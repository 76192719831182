import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Team from "../components/Team"
import TwoCol from "../components/TwoCol"
import Testimonials from "../components/Testimonials"
import Brands from "../components/Brands"
import ContentImage from "../components/ContentImage"

const logoFeat = [
  {
    name: 'Gatsby',
    image: require('../assets/svg/gatsby-1.svg'),
    link: 'https://www.gatsbyjs.org/showcase/fullstackhq.com',
    activeLink: true
  }, {
    name: 'Awards',
    image: require('../assets/svg/brands-awwwards-logo.svg'),
    link: 'https://www.awwwards.com/sites/psd-wizard-1',
    activeLink: true
  }, {
    name: 'Css Design Awards',
    image: require('../assets/svg/brands-cssdesign-logo.svg'),
    link: 'https://www.cssdesignawards.com/sites/psd-wizard/32529/',
    activeLink: true
  }, {
    name: 'JAMStack',
    image: require('../assets/svg/brands-jamstack-logo.svg'),
    link: 'https://jamstack.org/examples/',
    activeLink: true
  }, {
    name: 'React Static',
    image: require('../assets/svg/brands-react-logo.svg'),
    link: 'https://github.com/react-static/react-static#sites-built-with-react-static',
    activeLink: true
  }
]

const testimonyList = [
  {
    text: "Wow. U guys are simply amazing. This design is stunnning. Your design team rocks @Peng! They nailed it first go. Quite impressive",
    image: require('../assets/images/eddie-machaalani.png'),
    name: 'Eddie Machaalani',
    position: 'BigCommerce, Co-founder'
  },
  {
    text: `I was incredibly impressed by how you matched specs with clean code. And the team is super easy to work with!`,
    image: require('../assets/images/mohammed-abid.png'),
    name: 'Mohammed Abid',
    position: 'Facebook, Product Designer'
  }, {
    text: `The client (and me) are thrilled with the end result and are thankful for you and your team's great skills, communication, timeless, and quality of work!`,
    image: require('../assets/images/tim-haskins.png'),
    name: 'Tim Haskins',
    position: 'UpliftCX, CEO'
  }
]

const teamList = [
  {
    image: require('../assets/images/team/peng2024.png'),
    name: 'Christopher "Peng" Enriquez',
    position: 'CEO & Co-Founder',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">Peng Enriquez is an entrepreneur, product designer, and software engineer with 18 years of experience in the IT industry. He is the co-founder and CEO of Fullstack HQ and Raket.PH. Previously, he founded NarraSpark Inc, an IT services company based in Clark Freeport Zone, Philippines that was acquired in 2015.</p>
      <p className="member-desc p-md">Peng has deep experience leading and scaling tech companies. At NarraSpark, he grew the team from 7 to over 80 employees before its acquisition. As CEO of Fullstack HQ, he has self-funded the company to profitability over its 9 years of operation, working with clients like Accenture, Holcim, and BigCommerce founder.</p>
      <p className="member-desc p-md">In addition to entrepreneurship, Peng has spent years in software engineering roles. He was formerly a UX designer at Plotly, a leader in low-code data apps. Prior, he led development teams as a full stack engineer at companies including Zuellig Pharma, Takeda Pharmaceuticals, and Direct Technology.</p>
      <p className="member-desc p-md">In 2003, Peng was awarded "One of the Outstanding Computer Engineering Students" in Angeles University Foundation. He holds a Microsoft Certified Professional designation.</p>
    </div>
  },
  {
    image: require('../assets/images/team/carlet2024.png'),
    name: 'Carlet Berlly Enriquez',
    position: 'CFO & Co-Founder',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">Carlet is the CFO and co-founder of Fullstack HQ and Raket.PH, overseeing the finance and accounting functions. She manages the finances, prepares financial reports, and oversees cash flow and capital needs. Together with Peng, they bootstrapped Fullstack HQ from negative all the way to a steady positive cash flow. Carlet has established the company's culture that helped in growing a highly-competitive and driven team of 40+ in 2 years.</p>
      <p className="member-desc p-md">She graduated from Holy Angel University with a degree in BSBA major in Finance and Management Accounting. Prior to Fullstack HQ, Carlet spent 4 years in accounts receivable, UK payroll and financial management for her first startup IT company and for various local businesses. </p>
   </div>
  },
  {
    image: require('../assets/images/team/chona.png'),
    name: 'Chona Castro',
    position: 'Admin and Bookkeeper',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Chona is a skilled administrator and bookkeeper at Fullstack HQ with a passion for precision and organization. A graduate of Tarlac State University with a bachelor's degree in Accounting Technology. She is adept at handling a variety of administrative tasks, from managing schedules and coordinating meetings to maintaining accurate financial records and preparing reports. 
      </p>
      <p className="member-desc p-md">
        She have a proven track record of ensuring smooth office operations and financial integrity. With strong attention to detail and a commitment to efficiency, Chona is a valuable asset to any team.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/joshemel.png'),
    name: 'Joshemel Advincula',
    position: 'Project Coordinator',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Joshemel is the Project Coordinator and Software QA Tester of Fullstack HQ. She continues to prove her excellence in the company with her strength in client coordination and competence as a leader. After earning her degree in Information Technology at Don Honorio Ventura State University, she worked at Logicore Innovative Solution as a Software QA Tester prior to joining this company.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/leo.png'),
    name: 'Leonardo Ernesto Descanzo',
    position: 'Project Coordinator',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Leo is a Project Coordinator and Software QA Tester at Fullstack HQ. He holds a Bachelor of Science degree in Information Technology with a specialization in Web and Mobile Application from Tarlac State University, where he graduated as Cum Laude. During his college years, he was a consistent university scholar and dean's lister, and received the Academic Excellence award (2015-2019).
      </p>
      <p className="member-desc p-md">
        To deepen his knowledge and skills, he took a web development bootcamp under Village88, where he gained certifications in Web development fundamentals, Advanced PHP, and Quality Assurance. He finished the bootcamp as the 'Best Quality Assurance Capstone'.
      </p>
      <p className="member-desc p-md">
        Prior to joining the company, he worked as a Software Analyst at a tech company in Makati (2019) and was a Team Lead/Supervisor at a BPO company in Tarlac (2021-2023).
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/goldie.png'),
    name: 'Goldie Ann Torres',
    position: 'Lead Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Goldie is a web developer at Fullstack HQ, she is responsible for creating, checking, and updating websites according to the client's/company's needs.
      </p>
      <p className="member-desc p-md">
        She graduated with a Bachelor of Science in Computer Science at AMA Computer College. During her college years, she was included in the dean's list from 2016 to 2017. Aside from that, she also joined numerous competitions wherein she earned numerous titles: Highest pointer in TORO Inter IT Elite Challenge qualifying rounds (backend category); and Best programmer in WR Ines Phil. Inc. Also, she is currently a part-time instructor in CMFC, thesis panelist in AMA Computer College, and IT Expert for masteral thesis.
      </p>
      <p className="member-desc p-md">
        Prior to joining the company, she had worked as a database and system administrator, graphic designer and web developer in WR Ines Phil. Inc and a web developer in Netpoint Global as well.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/raquel-ann.png'),
    name: 'Raquel Ann Bondoc',
    position: 'Web Designer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Raquel is a talented web designer at Fullstack HQ, where she combines her technical skills and creative flair to craft exceptional digital experiences. A recent Summa Cum Laude graduate in Information Technology with Areas of Specialization in Web Development from Holy Angel University, she possesses a solid foundation in technology and design, enabling her to excel in the rapidly evolving tech industry.
      </p>
      <p className="member-desc p-md">
        In addition to web design, Raquel is skilled in UI/UX design, WordPress development, social media content creation, software QA testing, and front-end development. Her diverse expertise allows her to prioritize both functionality and user experience, and she is passionate about seeking new challenges to expand her knowledge and impact the digital landscape.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/jocar.png'),
    name: 'Jocar Jay David',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Jocar is a Web Developer at Fullstack HQ. He has been developing, maintaining, updating websites and collaborating with the team in any projects he’s assigned to, making him the employee of the month (Aug 2019).
      </p>
      <p className="member-desc p-md">
        Jocar is a graduate of Bachelor of Science in Computer Science at City College of Angeles. Prior to joining the company, he worked at EmergeLocal as a web developer.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/kaye.png'),
    name: 'Kaye Ann Layug',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Kaye serves as a Web Developer Trainer at Fullstack HQ. With a degree in Bachelor of Science in Information Technology, she assures quality in every project, which revolves around converting UI/UX design wireframes to actual code.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/ricardo.png'),
    name: 'Ricardo de Guzman III',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Ricardo is a Web Developer at Fullstack HQ. He has been supporting teams and doing Wordpress development. He graduated with a Bachelor of Science in Information Technology at Baliwag Polytechnic College.
      </p>
      <p className="member-desc p-md">
        Prior to joining the company, he worked at iConcept and Sytian IT Solutions as a Wordpress developer.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/naey.png'),
    name: 'Christian Naey Gonzales',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Naey is a Web Developer at Fullstack HQ. She converts PSD to HTML, provides pixel perfect websites, communicates with other team members and handles multiple projects. She graduated with a Bachelor of Science in Computer Science at City College of Angeles where she was on the dean's list. She had previously worked at My Intellects Inc. as a graphic designer.
      </p>
    </div>
 },
  {
    image: require('../assets/images/team/justine1.png'),
    name: 'Justine Nina Carreon',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Justine is a Web Developer at Fullstack HQ. Her strength leans on front-end development, and updating, creating and modifying contents on Shopify and WordPress. She graduated with Bachelor of Science in Computer Engineering at Holy Angel University where was dean's list from 2018 - 2019. 
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/rosalinda.png'),
    name: 'Rosalinda Soriano',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Rosalinda is a Web Developer at Fullstack HQ where she converts UI/UX design wireframes to actual code. She graduated with a Bachelor of Science in Computer Science at City College of Angeles. She also gained certifications in Arduino: Introduction & Programming, Arduino: A Hands-On Introductory Workshop, CCNA R&S: Introduction to Networks, CCNA R&S: Connecting Networks, and CCNA R&S: Scaling Networks. Prior to joining the company, she had worked at McDonalds and Jollibee as a service crew.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/adi.png'),
    name: 'Adi Lila Dasi Robles',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Adi is a web developer at Fullstack HQ where she converts UI/UX design wireframes to actual code. She graduated with a Bachelor of Science in Computer Science at AMA Computer College. During her college years, she was included in the dean's list and was an active member of the Honor Society.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/princeton.png'),
    name: 'Princeton Jude Cipriano',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
      Prince is a skilled IT professional who graduated from AMA Computer College with a degree in Information Technology and completed a fullstack web development bootcamp at KodeGo Ph. Previously, as a system developer at a system integrator and distributor company, Prince gained extensive experience working with IoT devices, integrating APIs, and developing web applications.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/tim.png'),
    name: 'Timothy Calaguas',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Timothy is a Web Developer at Fullstack HQ. His job focuses on frontend development, Tech Stack, Webflow, Shopify, React JS, and Next JS. He graduated with Bachelor of Science in Information Technology at Mabalacat City College where he excelled to be on president's and dean's lists.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/crisbern1.png'),
    name: 'Crisbern Gozun',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Crisbern is a Web Developer at Fullstack HQ. He is responsible for both front-end and back-end development. He earned his Computer Engineering degree at Don Honorio Ventura State University. He often works on the back-end part of the program using Python, Django, Node JS, Express, Strapi, MySQL, MongoDB, and PostgreSQL. During his college years, he excelled to be on the president's and dean's lists from 2018-2022. Also, he led his thesis group, which resulted to bagging the "Most Innovative Capstone Design" award.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/jovillene1.png'),
    name: 'Jovillene Peñalosa',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Jovillene graduated from Mabalacat City College with a Bachelor of Science in Information Technology. She is a front-end developer at Fullstack HQ focusing on WordPress, Shopify, Next.js, Vue.js, and React.js.
      </p>
      <p className="member-desc p-md">
        She also previously worked as a call center agent at IQOR and a cashier for a mall valet parking.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/richard1.png'),
    name: 'Richard Dela Cruz Jr',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Richard serves as a Web Developer at Fullstack HQ. With a degree in Bachelor of Science in Computer Science, he earned various university recognitions such as being a dean's list in 2019 and 2020 and membership at PSITE Region 3. His job focuses on front-end development with expertise in Shopify, Wordpress and Javascript. 
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/nica.png'),
    name: 'Nica Alimurung',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Nica is a Front-end Developer at Fullstack HQ.
        She is a Tarlac State University alumna holds a bachelor's degree in Information Technology, specializing in Web and Mobile Applications. Nica's commitment to academic success was evident as she graduated as Cum Laude.
        Nica is a web developer who specialized on converting figma designs into actual code.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/allen.png'),
    name: 'Allen Alipio',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Allen is a Front-end Developer at Fullstack HQ. A graduate of Tarlac State University, with a bachelor's degree in Information Technology. Throughout his academic journey, Allen's dedication to excellence shone brightly as he secured a spot on the dean's list from 2021 to 2023 and also graduated as a Cum Laude.
      </p>
      <p className="member-desc p-md">
        He is a web developer proficient on using Tailwind CSS, Angular, React, and Wordpress, while PHP on the backend.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/angel.png'),
    name: 'Mark Angel Dominisac',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Angel is a Front-end developer at Fullstack HQ. He graduated from Tarlac State University with a Bachelor of Science degree in Information Technology, specializing in Web and Mobile Application Development. He was a dean's lister for four consecutive years during his stay and graduated as a Magna Cum Laude. Additionally, he served as the Vice President of the Web Development division in an organization called Programmers' Den.
      </p>
      <p className="member-desc p-md">
        Prior to joining the company, he worked as a Project Lead/Subject Matter Expert at an IT company in Clark. There, he learned to interact effectively with clients and manage resources to deliver high-quality projects. He also provided support to his colleagues, further enhancing his programming knowledge.
      </p>
      <p className="member-desc p-md">
        Angel specializes in Shopify development and is proficient in WordPress, including Elementor, Oxygen, GeneratePress, and other builders, as well as in Next.js and React.js. He is passionate about creating websites to enhance user experiences.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/verj.png'),
    name: 'Zehrei Verj Morales',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Zehrei Verj is a Fullstack Web Developer at Fullstack HQ. He graduated from Angeles University Foundation in January 2023. A programmer that is well-versed in .NET, NodeJS, PHP; as for Front-end, React and Angular.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/mark.png'),
    name: 'Mark Raphael Nuguid',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Mark is a web developer at Fullstack HQ who specializes in constructing responsive and visually appealing websites with React JS, Next JS, and Tailwind CSS. He also specializes in integrating back-end services and developing basic back-end services with Laravel. 
      </p>
      <p className="member-desc p-md">
        He is also a graduate student from Holy Angel University with a degree in Bachelor of Science in Information Technology with an area of specialization in Web Development. In addition to his work activities, Mark volunteers at the Center for Kapampangan Studies, where he skillfully converts concepts and historical accounts into websites and web applications aimed at preserving and promoting Kapampangan Culture.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/cathlyn.png'),
    name: 'Cathlyn Mae Lapid',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
      Cathlyn is a Web Developer at Fullstack HQ, experienced in developing web applications using HTML, CSS, Javascript, and PHP but mostly adept is back-end development using the Laravel Framework.
      </p>
      <p className="member-desc p-md">
      Cathlyn earned her bachelor's degree in computer science from Systems Plus College Foundation. Since her third year, she has been involved with a school organization that develops web applications for the school and its partners, which helped her gain experience and improve her web development skills. She received the Programmer of the Year award and the Excellence in Innovation in Software Development award for her contributions to the school's ICT development unit and for participating in competitions such as hackathons.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/lemuelle.png'),
    name: 'Jean Lemuelle Bagay',
    position: 'Developer',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Lemuelle is a Web Developer at Fullstack HQ, a Game Developer at night and a Backend Developer at day. He is experienced in web development mostly using Laravel Framework and Livewire.
      </p>
      <p className="member-desc p-md">
        A graduate from Tarlac State University with a BS in Information and Technology Specializing in Web and Mobile Application and an award of Magna Cum Laude. He is a consistent dean's lister but only started to ignite his passion in Web Development in his third year in TSU. He started to get involved in competitions like Hack4Gov and Hackathon in his latter year which helped him hone his skills and gain experience.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/francisco.png'),
    name: 'Francis Enriquez Jr.',
    position: 'IT Administrator',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Francis handle current position as a IT Admin at Fullstack HQ, He focusing on maintenance of computer hardware, internet server problem and other computer components problem.
      </p>
    </div>
  },
  {
    image: require('../assets/images/team/raymundo.png'),
    name: 'Raymundo Diaz',
    position: 'Security',
    description:
    <div className="member-desc-holder">
      <p className="member-desc p-md">
        Raymundo serves as the Security Officer at Fullstack HQ, He is committed to ensuring the safety of both employees and visitors.
      </p>
      <p className="member-desc p-md">
        He contributes to the safe and secure environment integral to the company's operations. His tenure dates back to the inception of Fullstack HQ, reflecting his enduring commitment to its security from its earliest stages.
      </p>
    </div>
  }
]

const aboutContent = [
  {
    image: require('../assets/images/building.jpg'),
    name: 'Fullstack HQ Office',
    title: 'About Fullstack HQ',
    desc: 'Fullstack HQ is a web development outsource company where 100% of the work is done in-house. We partner with world-class entrepreneurs, design studios, tech companies, marketing agencies and startups helping them to increase efficiency & reduce development costs.'
  },
  {
    image: require('../assets/images/awards.jpg'),
    otherImage: require('../assets/images/honorable.jpg'),
    name: 'Fullstack HQ Awards',
    title: 'Award-Winning Company',
    desc: 'Our company has been awarded an "Honorable mention" from Awwwards and three awards from CSSDesignAwards. Those awards being Best UI Design, UX Design and Innovation. We’re also featured on Gatsby, React Static and JAMstack official websites.'
  },
   {
    image: require('../assets/images/fshq_team_2022.png'),
    name: 'Fullstack HQ Team',
    title: 'Agile Team Organization',
    desc: 'We aren’t one of those ‘expert’ outsourcing companies that try to do everything. We specialize in one thing – and do it really well, full stack development. Fullstack HQ brings agile development teams with difficult-to-find expertise to execute faster and more cost effectively than traditional methods.'
  },
  {
    image: require('../assets/images/kulitan.jpg'),
    name: 'Fullstack HQ Events',
    title: "We're connected to the digital community",
    desc: 'We can frequently be found speaking at the industry events as well as hosting various educational tours and workshops in our office.'
  },
]

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  noImage: false,
  image: require('../assets/images/about-banner.png'),
  title: "The smarter way to outsource web development projects",
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

const ctaJoin = {
  hasBackground: true,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  noImage: true,
  noImageCTA: true,
  title: 'Grow with us',
  desc: "Play a major role in one of the fastest growing tech companies in the Philippines.",
  buttons: [
    {
      type: 'normal',
      text: 'View current vacancies',
      link: '/careers',
      beacon: false
    }
  ]
}

function About() {
  return (
    <div className="member-desc-holder">
      <OgTags
        ogTitle="Full Stack Web Design and Development Team Philippines | Fullstack HQ"
        ogDescription="Fullstack HQ is on a mission to empower the future of work by connecting digitals experts in Philippines to businesses around the world."
        ogUrl="https://fullstackhq.com/about"
      />
      <Header />
      <main className="about initialized-header">
        <ContentImage content={ cta }  />
        <TwoCol
          about = {aboutContent}
        />
        <Team
          team = { teamList }
        />
        <Testimonials
          title="Loved by world-class entrepreneurs & tech companies"
          testimony={ testimonyList }
        />
        <Brands
          text="We've been featured on"
          brands={ logoFeat }
        />
         <ContentImage content={ ctaJoin }  />
      </main>
      <Footer />
   </div>
  )
}

export default About
