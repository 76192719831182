import React from "react"

function TwoCol(props) {
  const {about} = props

  return (
    <div className="section-two-col">
      <div className="container">
        <div className="content-wrapper">
        { about.map((about, i) => {
          return(
            <div key={ i } className="content-holder">
              <div className={!about.otherImage ? "image-holder" : "image-holder image-holder-custom"}>
                { about.otherImage ? <img src={ about.otherImage } className="img-fluid img-col img-other" alt={ about.name } /> : null}
                <img src={ about.image } className="img-fluid img-col" alt={ about.name } />
              </div>
              <div className="desc-holder">
                <h2 className="desc-title">{ about.title }</h2>
                <p className="p-md">{ about.desc }</p>
              </div>
            </div>
          )
        }) }
        </div>
      </div>
    </div>
  )
}

export default TwoCol
