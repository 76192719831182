import React, {useState, useEffect} from "react"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import clsx from "clsx"

// let index = 0;
    
function Team(props) {
  const {team} = props
  const [index, setIndex] = useState(0)
  
  const [swiper, updateSwiper] = useState(null)
  const [isModalActive, setIsModalActive] = useState(false)

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    activeSlideKey: 0,
    breakpoints: {
      767: {
        autoHeight: true
      }
    }
  }

  function handleMemberInfo(member) {
    document.body.classList.add('body-overflow')

    setIsModalActive(true)

    team.forEach((item, i) => {
      if(member.name === item.name){
        setIndex(i)
      }
    })
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(index, 0)
    }
  }, [index])
  

  function handleCloseModal() {
    document.body.classList.remove('body-overflow')
    setIsModalActive(false)
  }

  return (
    <div className="section-leadership-team">
      <div className="container">
        <h2 className="text-center">Fullstack HQ Team</h2>
        <div className="leadership-team-wrapper">
          { team.map((member, i) => {
            return (
              <div key={ i } className="member-holder" onClick={() => handleMemberInfo(member)}>
                <img src={ member.image }  className="img-team" alt={ member.name }/>
                <div className="member-detail">
                  <p className="member-name p-md text-center">{ member.name }</p>
                  <p className="member-position p-md text-center">{ member.position }</p>
                </div>
              </div>
            )
          }) }
          <div className={clsx('modal-dialog', {'is-open':isModalActive})}>
            <div className="modal-wrapper">
              <div className="modal-header">
                <div title="Close" className="close" onClick={ handleCloseModal }><span className="icon-close"></span></div>
              </div>
              <div className="modal-body">
                <Swiper {...params} getSwiper={updateSwiper}>
                  {team.map((value, i) => {
                    return (
                      <div className="swiper-slide" key={i} >
                        <div className="content-wrapper">
                          <div className="content-holder">
                            <img src={ value.image } className="img-team" alt={ value.name }/>
                            <div className="member-detail">
                              <p className="member-name p-md text-center">{ value.name }</p>
                              <p className="member-position p-md text-center">{ value.position }</p>
                              { value.description }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Swiper>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
